/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import card_img from "../../../src/assets/images/card_img.png";
import bookmark from "../../../src/assets/images/bookmark.svg";
import secondsToHms from "../../common/SecondsToHms/secondsToHms";
import time from "../../../src/assets/images/time.svg";
import vc from "../../../src/assets/images/vc-icon.svg";
import classroomIcon from "../../../src/assets/images/classroom.svg";
import activityIcon from "../../../src/assets/images/activity.svg";
import docIcon from "../../../src/assets/images/doc.svg";
import pdfIcon from "../../../src/assets/images/pdf.svg";
import AiccIcon from "../../../src/assets/images/aicc.svg";
import CpIcon from "../../../src/assets/images/cp.svg";
import video from "../../../src/assets/images/video.svg";
import pptIcon from "../../../src/assets/images/pptIcon.svg";
import audio from "../../../src/assets/images/audio.svg";
import excel from "../../../src/assets/images/excel.svg";
import msword from "../../../src/assets/images/msword.svg";
import scormtincan from "../../../src/assets/images/scormtincan.svg";
import quiz from "../../../src/assets/images/quiz.svg";
import ClassRoomVirtualClassroomTypeDetails from "./ClassRoomVirtualClassroomTypeDetails";
import "primeicons/primeicons.css";
import PrerequisiteCourse from "./PrerequisiteCourse";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apis } from "../../services/Api";
import { signin_url } from "../../AppConfig";
import Evaporate from "evaporate";
import { getToken } from "../../services/Auth";
import AWS from "aws-sdk";
import ModalforSuccessAndFailure from "../../common/Modal/Modal";

export default function SingleInstanceDetails({
    coreContentItem,
    startUi,
    courseDetails,
    isVisibleBtn,
    prerequisiteCourse,
    crumbs,
    // isRevisit,
    // hasPassed,
    apiCall,
    handleLoader,
    name
}) {
    const [showModal, setShowModal] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [warningPopup, setWarningPopup] = useState("");
    const [messageForFailure, setMessageForFailure] = useState("");
    const [modulecount, setModuleCount] = useState()
    let { classroomData, duration } = coreContentItem;
    const moduleImage = (item) => {
        if (item?.contentType === "Virtual Classroom") {
            return <img className="list-view-block-img" src={vc} alt="images" />;
        } else if (item?.contentType === "Classroom") {
            return <img className="list-view-block-img" src={classroomIcon} alt="images" />;
        } else if (item?.contentType === "Activity") {
            return <img className="list-view-block-img" src={activityIcon} alt="images" />;
        } else if (item?.contentType === "DOC") {
            return <img className="list-view-block-img" src={docIcon} alt="images" />;
        } else if (item?.contentType === "PDF") {
            return <img className="list-view-block-img" src={pdfIcon} alt="images" />;
        } else if (item?.contentType === "AICC") {
            return <img className="list-view-block-img" src={AiccIcon} alt="images" />;
        } else if (item?.contentType === "CP") {
            return <img className="list-view-block-img" src={CpIcon} alt="images" />;
        } else if (item?.contentType === "VIDEO") {
            return <img className="list-view-block-img" src={video} alt="images" />;
        } else if (item?.contentType === "OTHER") {
            return <img className="list-view-block-img" src={AiccIcon} alt="images" />;
        } else if (item?.contentType === "PPT") {
            return <img className="list-view-block-img" src={pptIcon} alt="images" />;
        } else if (item?.contentType === "AUDIO") {
            return <img className="list-view-block-img" src={audio} alt="images" />;
        } else if (item?.contentType === "XLS") {
            return <img className="list-view-block-img" src={excel} alt="images" />;
        } else if (item?.contentType === "MS_WORD") {
            return <img className="list-view-block-img" src={msword} alt="images" />;
        } else if (item?.contentType === "SCORM12") {
            return <img className="list-view-block-img" src={scormtincan} alt="images" />;
        } else if (item?.contentType === "SCORM2004") {
            return <img className="list-view-block-img" src={scormtincan} alt="images" />;
        } else if (item?.contentType === "TINCAN") {
            return <img className="list-view-block-img" src={scormtincan} alt="images" />;
        } else if (item?.contentType === "QUIZ") {
            return <img className="list-view-block-img" src={quiz} alt="images" />;
        }
    };
    const miid = coreContentItem.id;
    const ciid = miid.split("_")[0];
    const navigate = useNavigate();
    const location = useLocation();



    // let crumbs1 = crumbs?.length > 0 && [...crumbs.slice(0, -1)];

    function getCheckToken() {
        const response = getToken();
        const result = response.access_token;
        return result;
    }

    var UPLOAD_SINGER_URL = `${signin_url}/primeapi/v2/uploadSigner`;
    let papiAwsCredJsonObj = {
        awsKey: "",
        bucket: "",
        region: "",
        awsUrl: "",
        key: ""
    };

    async function getUploadInfo() {
        const response = await apis.getUploadInfo();
        const result = response?.data;
        saveAwsCred(result);
    }

    function saveAwsCred(result) {
        papiAwsCredJsonObj = result;
        const awsRegionUrl =
            papiAwsCredJsonObj?.region.indexOf("us-") === 0
                ? ".s3.amazonaws.com/"
                : ".s3." + papiAwsCredJsonObj?.region + ".amazonaws.com/";
        const awsUrl =
            window?.location?.host?.indexOf("9010") === -1
                ? "https://" + papiAwsCredJsonObj?.bucket + awsRegionUrl + papiAwsCredJsonObj?.key
                : "http://localhost:4567/" +
                papiAwsCredJsonObj?.bucket +
                "/" +
                papiAwsCredJsonObj?.key;
        papiAwsCredJsonObj.awsUrl = awsUrl;
        // setIsLoading(true)
        initEvaporate(getEvaporateConfig());
    }
    const handleCloseModal = () => {
        setShowModal(false);
        setWarningPopup("");
        apiCall()
    };
    function getEvaporateConfig() {
        return {
            aws_key: papiAwsCredJsonObj?.awsKey,
            bucket: papiAwsCredJsonObj?.bucket,
            awsRegion: papiAwsCredJsonObj?.region,
            cloudfront: true,
            xhrWithCredentials: true,
            signerUrl: UPLOAD_SINGER_URL,
            awsSignatureVersion: "4",
            computeContentMd5: true,
            signHeaders: {
                Authorization: "oauth " + getCheckToken(),
                "Content-Type": "application/vnd.api+json",
                Origin: "localhost"
            },
            aws_url: papiAwsCredJsonObj?.awsUrl,
            cryptoMd5Method: function (data) {
                return AWS?.util?.crypto?.md5(data, "base64");
            },
            cryptoHexEncodedHash256: function (data) {
                return AWS?.util?.crypto?.sha256(data, "hex");
            }
        };
    }

    function initEvaporate(config) {
        Evaporate.create(config).then(
            function success(_e_) {
                // let fileInput = document.getElementById("files"),
                let fileInput = document.getElementsByClassName("filesubmission");
                for (let i = 0; i < fileInput.length; i++) {
                    let filePromises = [];
                    fileInput[i].onchange = function (evt) {
                        handleLoader(true)
                        sessionStorage.setItem("fileSubmissionLoResId", this.id);
                        // setIsLoading(true); // Set loading state to true
                        let file_name;
                        let files = evt.target.files;
                        for (let i = 0; i < files.length; i++) {
                            // const timestamp = new Date().getTime();
                            const file = files[i];
                            const filename = file.name;
                            const validFilenamePattern = /^[a-zA-Z0-9._\-' ()[\]]+$/;

                            if (!validFilenamePattern.test(filename)) {
                                handleLoader(false);
                                setMessageForFailure("");
                                setMessageforPopup("");
                                setWarningPopup("File name contains special character. Please ensure that the file name only consists of letters, numbers, and standard punctuation marks. Avoid using symbols such as *, %, $, @, &, # etc.")
                                setShowModal(true);
                                return;
                            }
                            let promise = _e_
                                .add({
                                    name: filename,
                                    file,
                                    progress: function (progress) { }
                                })
                                .then(function (awsKey) {
                                    file_name = awsKey;
                                });
                            filePromises.push(promise);
                        }
                        Promise.all(filePromises).then(
                            async function () {
                                const imgurl = papiAwsCredJsonObj?.awsUrl + "/" + file_name;

                                if (imgurl !== null) await uploadFile(imgurl);
                            },
                            function (reason) { }
                        );
                        evt.target.value = "";
                    };
                }
            },
            function failure(reason) { }
        );
    }


    const uploadFile = async (imageUrl) => {
        handleLoader(true)
        try {
            let loId = courseDetails.id;
            let loResourceId = sessionStorage.getItem("fileSubmissionLoResId");
            sessionStorage.removeItem("fileSubmissionLoResId");
            const payload = {
                data: {
                    id: loResourceId,
                    type: "learningObjectResource",
                    attributes: {
                        externalReporting: true,
                        loResourceType: "Content",
                        multipleAttemptEnabled: false,
                        previewEnabled: false,
                        resourceSubType: "SUBMISSION",
                        resourceType: "ACTIVITY",
                        submissionEnabled: true,
                        submissionState: "PENDING_SUBMISSION",
                        submissionUrl: imageUrl
                    }
                }
            };

            const response = await apis.uploadFile(payload, loId, loResourceId);
            handleLoader(false, true, response)
        } catch (e) {
            console.log(e);
            handleLoader(false); // Set loading state to true
        }
    }

    const handleDownload = (fileUrl) => {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "downloaded_file.txt";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const extractFileName = (coreContentItem) => {
        if (!coreContentItem || !coreContentItem.submissionUrl) {
            return null;
        }

        try {
            const url = coreContentItem.submissionUrl;
            const urlObject = new URL(url);
            const pathSegments = urlObject.pathname.split("/");
            const lastSegment = pathSegments[pathSegments.length - 1];
            const stringWithoutPercent20 = lastSegment.replace(/%20/gi, " ");
            return stringWithoutPercent20;
        } catch (error) {
            console.error("Error parsing URL:", error.message);
            return null;
        }
    };

    const fileName = extractFileName(coreContentItem);

    useEffect(() => {
        getUploadInfo();
        extractFileName();

        let moduleCountArr = [];
        if (coreContentItem.mandatory === true) {
            moduleCountArr.push(true)
        }
        setModuleCount(moduleCountArr)
    }, []);

    const playCourse = (cid, mid) => {
        navigate(`/course-player/${cid}`, {
            state: {
                cid,
                mid,
                goBackUrl: location?.pathname,
                crumbs
            }
        });
    };
    const isRevisit = coreContentItem?.progressPercent
    const hasPassed = coreContentItem?.hasPassed
    return (
        <>
            <div className="list-view">
                {/*<h3>Core Content</h3>*/}
                <div className="row mb-4 list-view-block cd justify-content-center justify-content-md-start mx-0">
                    <div className="col-auto py-4 px-4">{moduleImage(coreContentItem)}</div>
                    <div className="col pe-4 text-padding">
                        <p className="head-text">
                            {
                                (coreContentItem?.resourceSubType === "SUBMISSION" && coreContentItem.submissionEnabled) ? (
                                    (coreContentItem.submissionState === "APPROVED" || !courseDetails?.relationships?.enrollment || !isVisibleBtn) ? (
                                        <span style={{cursor: "pointer"}}>{coreContentItem?.name}</span>
                                    ) : (
                                        <label style={{cursor: "pointer"}} htmlFor={coreContentItem?.id}>{coreContentItem?.name}</label>
                                    )
                                ) : (
                                    <button
                                        className="head-text btn-as-p"
                                        onClick={() => {
                                            playCourse(ciid, miid);
                                        }}
                                        disabled={courseDetails?.relationships?.enrollment ? !isVisibleBtn : true}
                                    >{coreContentItem?.name}</button>
                                )
                            }
                            {
                                coreContentItem.mandatory && ((coreContentItem.loResourceCompletionCount !== coreContentItem.learningObjectInstanceModuleLength) && (coreContentItem.mandatory === true) ? <span className="section-btn">Required</span> : <span className="optional-btn">Optional</span>)
                            }
                            {
                                coreContentItem.mandatory === false && ((coreContentItem.loResourceCompletionCount !== coreContentItem.learningObjectInstanceModuleLength) && (coreContentItem.mandatory === false) ? <span className="optional-btn">Optional</span> : null)
                            }


                            {courseDetails?.relationships?.enrollment && (
                                coreContentItem?.resourceSubType === "CHECKLIST" ? (
                                    coreContentItem?.checkList === "PENDING" ? (
                                        <span className="notStarted-btn">Not Started </span>
                                    ) : coreContentItem?.checkList === "PASSED" ? (
                                        <span className="completed-btn">Completed </span>
                                    ) : coreContentItem?.checkList === "FAILED" ? (
                                        <span className="completed-btn">Completed </span>
                                    ) : null
                                ) : coreContentItem?.resourceSubType === "SUBMISSION" ? (
                                    coreContentItem?.submissionState === "PENDING_SUBMISSION" ? (
                                        <span className="notStarted-btn">Not Started </span>
                                    ) : coreContentItem?.submissionState === "APPROVED" ? (
                                        <span className="completed-btn">Completed </span>
                                    ) : coreContentItem?.submissionState === "REJECTED" ? (
                                        <span className="inprogress-btn">In Progress </span>
                                    ) : coreContentItem?.submissionState === "PENDING_APPROVAL" ? (
                                        <span className="inprogress-btn">In Progress </span>
                                    ) : null
                                ) : coreContentItem?.resourceSubType === "NONE" ? (
                                    hasPassed ? (
                                        <span className="completed-btn">Completed </span>
                                    ) : coreContentItem?.learnerAttemptInfo === "true" ? (
                                        <span className="inprogress-btn">In Progress </span>
                                    ) : <span className="notStarted-btn">Not Started </span>
                                ) : (
                                    hasPassed ? (
                                        <span className="completed-btn">Completed </span>
                                    ) : isRevisit > 0 && isRevisit <= 100 ? (
                                        <span className="inprogress-btn">In Progress </span>
                                    ) : (
                                        <span className="notStarted-btn">Not Started </span>
                                    )
                                )
                            )}

                        </p>
                        <p className="sub-text">{coreContentItem?.description}</p>
                    </div>
                    <div className="list-footer align-items-center row mx-0">
                        <div className="py-2 col-lg-9 col-md-9 px-0">
                            <img src={bookmark} className="px-2" alt=""></img>
                            <span className="module">{coreContentItem?.contentType}</span>
                            <span className="my-2 inner-span">
                                <span className="capsWord">
                                    {coreContentItem?.loType === "learningProgram"
                                        ? "Learning Path"
                                        : coreContentItem?.loType}
                                </span>
                                <span className="seprator px-2"> | </span>
                                {courseDetails?.attributes?.authorNames?.map((name, i) => (
                                    <span key={i} className="capsWord">
                                        {name}
                                        {i < courseDetails.attributes.authorNames.length - 1
                                            ? ", "
                                            : ""}
                                    </span>
                                ))}

                                {
                                    (
                                        <>
                                            <span className="seprator px-2"> | </span>

                                            <span>
                                                <img src={time} className="px-2" alt="" title="Duration" style={{ "marginBottom": "-1px" }}></img>
                                                {secondsToHms(coreContentItem?.duration) || "No Duration"}
                                            </span>

                                        </>
                                    )
                                }

                                {/* ----------------- */}

                                <span>
                                    <span className="px-2">
                                        {(courseDetails?.relationships?.enrollment) && (coreContentItem?.resourceSubType === "CHECKLIST" ? (
                                            coreContentItem?.checkList === "PENDING" ? (<>
                                                <span className="seprator px-2"> | </span>
                                                <span style={{ fontWeight: "bold", fontSize: "15px", color: "#CB9015" }}>
                                                    Reviewer evaluation is pending
                                                </span>
                                            </>
                                            ) : coreContentItem?.checkList === "PASSED" ? (
                                                <>
                                                    <span className="seprator px-2"> | </span>
                                                    <span style={{ fontWeight: "bold", fontSize: "15px", color: "#009C3B" }}>
                                                        Reviewer evaluation is complete. Status -{" "}
                                                    </span>
                                                    <span style={{ fontWeight: "bold", color: "#009C3B" }}>Passed</span>
                                                </>
                                            ) : coreContentItem?.checkList === "FAILED" ? (
                                                <>
                                                    <span className="seprator px-2"> | </span>
                                                    <span style={{ fontWeight: "bold", fontSize: "15px", color: "#EB1000" }}>
                                                        Reviewer evaluation is complete. Status -{" "}
                                                    </span>
                                                    <span style={{ fontWeight: "bold", color: "#EB1000" }}>Failed</span>
                                                </>
                                            ) : null
                                        ) : null)}
                                    </span>
                                </span>

                                <span>
                                    {(courseDetails?.relationships?.enrollment) && coreContentItem?.resourceSubType === "SUBMISSION" && (
                                        <>
                                            <span className="seprator px-2"> | </span>

                                            <span className="sub-text">
                                                {coreContentItem?.resourceSubType === "SUBMISSION"
                                                    ? coreContentItem?.submissionState ===
                                                        "PENDING_APPROVAL"
                                                        ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#CB9015" }}>File submission is awaiting for approval : </span>
                                                        : coreContentItem?.submissionState ===
                                                            "APPROVED"
                                                            ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#009C3B" }}>File submission is approved : </span>
                                                            : coreContentItem?.submissionState ===
                                                                "PENDING_SUBMISSION"
                                                                ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#CB9015" }}>File submission is pending  </span>
                                                                : coreContentItem?.submissionState ===
                                                                    "REJECTED"
                                                                    ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#EB1000" }}>File submission is rejected : </span>
                                                                    : ""
                                                    : ""}
                                            </span>
                                        </>
                                    )}
                                </span>

                                {((courseDetails?.relationships?.enrollment) && coreContentItem.submissionEnabled) && (
                                    <>
                                        <span className="sub-text">
                                            <a
                                                href="#!" className="file-link" title={fileName}
                                                onClick={() => {
                                                    handleDownload(coreContentItem?.submissionUrl);
                                                }} >
                                                {fileName}
                                            </a>
                                        </span>
                                    </>
                                )}

                                {/* ------------------------- */}

                            </span>
                        </div>

                        {(coreContentItem?.resourceSubType === "SUBMISSION") &&
                            coreContentItem.submissionEnabled ? (
                            <>
                                <div className="text-padding text-end col-lg-3 col-md-3 px-0">
                                    {coreContentItem.submissionState === "PENDING_APPROVAL" && coreContentItem.submissionUrl !== "" &&
                                        <div className={`btn primary-btn ${courseDetails?.relationships?.enrollment ? (!isVisibleBtn ? "disabled" : "") : "disabled"}`}>
                                            <label htmlFor={coreContentItem?.id}> Change File </label></div>
                                    }

                                    {coreContentItem.submissionState === "PENDING_SUBMISSION" && coreContentItem.submissionUrl == "" &&
                                        <div className={`btn primary-btn ${courseDetails?.relationships?.enrollment ? (!isVisibleBtn ? "disabled" : "") : "disabled"}`}> <label htmlFor={coreContentItem?.id}> Upload File </label> </div>
                                    }

                                    {coreContentItem.submissionState === "REJECTED" && coreContentItem.submissionUrl !== "" && <div className={`btn primary-btn ${courseDetails?.relationships?.enrollment ? (!isVisibleBtn ? "disabled" : "") : "disabled"}`}> <label htmlFor={coreContentItem?.id}> Upload File </label> </div>}

                                    <input
                                        type="file"
                                        id={coreContentItem?.id}
                                        className="filesubmission"
                                        style={{ display: "none", border: "none" }}
                                    />
                                </div>
                            </>
                        ) : (
                            (
                            <div className="text-padding text-end col-lg-3 col-md-3 px-0">
                                <button
                                    className="btn primary-btn"
                                    onClick={() => {
                                        playCourse(ciid, miid);
                                    }}
                                    disabled={
                                        // !courseDetails?.relationships?.enrollment
                                        courseDetails?.relationships?.enrollment
                                            ? !isVisibleBtn
                                            : true
                                        // !isVisibleBtn
                                    }>
                                    {(
                                        coreContentItem?.resourceSubType === "CHECKLIST" ? (
                                            (coreContentItem?.checkList === "PENDING" || coreContentItem?.checkList === "") ? "Play Course" : "Revisit"
                                        ) : (
                                            coreContentItem?.resourceSubType === "NONE" ? (
                                                hasPassed ? "Revisit" :
                                                    coreContentItem?.learnerAttemptInfo === "true" ? "Continue" : "Play Course"
                                            ) : (
                                                hasPassed
                                                    ? "Revisit"
                                                    : isRevisit > 0 && isRevisit <= 100
                                                        ? "Continue"
                                                        : "Play Course"
                                            )
                                        )
                                    )}
                                </button>
                            </div>)
                        )}
                        {/* {isVisibleBtn ? <div className="">{startUi(coreContentItem?.id)}</div> : null} */}

                        {coreContentItem.contentType == "Classroom" ||
                            coreContentItem.contentType == "Virtual Classroom" ? (
                            <div className="row mb-4 list-view-block mx-0">
                                <ClassRoomVirtualClassroomTypeDetails
                                    item={{
                                        ...classroomData,
                                        duration,
                                        contentType: coreContentItem?.contentType,
                                        virtualrecording: coreContentItem?.sessionRecording
                                    }}
                                    type="course"
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            <ModalforSuccessAndFailure messageforPopup={messageforPopup} warningPopup={warningPopup} messageForFailure={messageForFailure} show={showModal} onClose={handleCloseModal} />
        </>
    );
}
