/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import card_img from "../../assets/images/card_img.png";
import lp_card_img from "../../assets/images/lp_card_img.png";
import certi_card_img from "../../assets/images/certi_card_img.png";
import jobaid_card_img from "../../assets/images/jobaid_card_img.png"
import bookmark from "../../../src/assets/images/bookmark.svg";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import import_icon from "../../../src/assets/images/import_importcsv.png";
import time from "../../../src/assets/images/time.svg";
import vc from "../../../src/assets/images/vc-icon.svg";
import classroomIcon from "../../../src/assets/images/classroom.svg";
import activityIcon from "../../../src/assets/images/activity.svg";
import docIcon from "../../../src/assets/images/doc.svg";
import pdfIcon from "../../../src/assets/images/pdf.svg";
import AiccIcon from "../../../src/assets/images/aicc.svg";
import CpIcon from "../../../src/assets/images/cp.svg";
import video from "../../../src/assets/images/video.svg";
import pptIcon from "../../../src/assets/images/pptIcon.svg";
import audio from "../../../src/assets/images/audio.svg";
import excel from "../../../src/assets/images/excel.svg";
import msword from "../../../src/assets/images/msword.svg";
import scormtincan from "../../../src/assets/images/scormtincan.svg";
import locator from "../../../src/assets/images/vc-map.svg";
import quiz from "../../../src/assets/images/quiz.svg";
import "primeicons/primeicons.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// ClassRoomVirtualClassroomTypeDetails;

import secondsToHms from "../../common/SecondsToHms/secondsToHms";
import ClassRoomVirtualClassroomTypeDetails from "./ClassRoomVirtualClassroomTypeDetails";
import PrerequisiteCourse from "./PrerequisiteCourse";
import { getLerningObjectCourses } from ".";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from "react-i18next";
import { apis } from "../../services/Api";
import { signin_url } from "../../AppConfig";
import Evaporate from "evaporate";
import { getToken } from "../../services/Auth";
import AWS from "aws-sdk";
import ModalforSuccessAndFailure from "../../common/Modal/Modal";
function CustomToggle({ eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => console.warn("totally custom!"));

  return <Button variant="default" className="accordionBtn" onClick={decoratedOnClick}></Button>;
}

const moduleImage = (item) => {
  if (item?.attributes?.contentType === "Virtual Classroom") {
    return <img className="list-view-block-img" src={vc} alt="images" />;
  } else if (item?.attributes?.contentType === "Classroom") {
    return <img className="list-view-block-img" src={classroomIcon} alt="images" />;
  } else if (item?.attributes?.contentType === "Activity") {
    return <img className="list-view-block-img" src={activityIcon} alt="images" />;
  } else if (item?.attributes?.contentType === "DOC") {
    return <img className="list-view-block-img" src={docIcon} alt="images" />;
  } else if (item?.attributes?.contentType === "PDF") {
    return <img className="list-view-block-img" src={pdfIcon} alt="images" />;
  } else if (item?.attributes?.contentType === "AICC") {
    return <img className="list-view-block-img" src={AiccIcon} alt="images" />;
  } else if (item?.attributes?.contentType === "CP") {
    return <img className="list-view-block-img" src={CpIcon} alt="images" />;
  } else if (item?.attributes?.contentType === "VIDEO") {
    return <img className="list-view-block-img" src={video} alt="images" />;
  } else if (item?.attributes?.contentType === "OTHER") {
    return <img className="list-view-block-img" src={AiccIcon} alt="images" />;
  } else if (item?.attributes?.contentType === "PPT") {
    return <img className="list-view-block-img" src={pptIcon} alt="images" />;
  } else if (item?.attributes?.contentType === "AUDIO") {
    return <img className="list-view-block-img" src={audio} alt="images" />;
  } else if (item?.attributes?.contentType === "XLS") {
    return <img className="list-view-block-img" src={excel} alt="images" />;
  } else if (item?.attributes?.contentType === "MS_WORD") {
    return <img className="list-view-block-img" src={msword} alt="images" />;
  } else if (item?.attributes?.contentType === "SCORM12") {
    return <img className="list-view-block-img" src={scormtincan} alt="images" />;
  } else if (item?.attributes?.contentType === "SCORM2004") {
    return <img className="list-view-block-img" src={scormtincan} alt="images" />;
  } else if (item?.attributes?.contentType === "TINCAN") {
    return <img className="list-view-block-img" src={scormtincan} alt="images" />;
  } else if (item?.attributes?.contentType === "QUIZ") {
    return <img className="list-view-block-img" src={quiz} alt="images" />;
  }
};

export default function CertificateInstanceDetails({
  courseIndex,
  coreContentItem,
  courseDetails,
  startUi,
  prerequisiteCourse,
  crumbs,
  isVisibleBtn,
  isPreviousInstanceCompleted,
  apiCall,
  handleLoader
}) {
  const [nestedItem, setNestedItem] = useState([]);
  const [key, setKey] = useState("Module")
  const [showModal, setShowModal] = useState(false);
  const miid = coreContentItem.id;
  const ciid = miid.split("_")[0];
  const navigate = useNavigate();
  const location = useLocation();
  let crumbs1 = crumbs?.length > 0 && [...crumbs.slice(0, -1)];
  const [messageforPopup, setMessageforPopup] = useState("");
  const [warningPopup, setWarningPopup] = useState("");
  const [messageForFailure, setMessageForFailure] = useState("");

  // --------------------file submission------------------
  function getCheckToken() {
    const response = getToken();
    const result = response.access_token;
    return result;
  }

  var UPLOAD_SINGER_URL = `${signin_url}/primeapi/v2/uploadSigner`;
  let papiAwsCredJsonObj = {
    awsKey: "",
    bucket: "",
    region: "",
    awsUrl: "",
    key: ""
  };

  async function getUploadInfo() {
    const response = await apis.getUploadInfo();
    const result = response?.data;
    saveAwsCred(result);
  }

  function saveAwsCred(result) {
    papiAwsCredJsonObj = result;
    const awsRegionUrl =
      papiAwsCredJsonObj?.region.indexOf("us-") === 0
        ? ".s3.amazonaws.com/"
        : ".s3." + papiAwsCredJsonObj?.region + ".amazonaws.com/";
    const awsUrl =
      window?.location?.host?.indexOf("9010") === -1
        ? "https://" + papiAwsCredJsonObj?.bucket + awsRegionUrl + papiAwsCredJsonObj?.key
        : "http://localhost:4567/" +
        papiAwsCredJsonObj?.bucket +
        "/" +
        papiAwsCredJsonObj?.key;
    papiAwsCredJsonObj.awsUrl = awsUrl;
    // setIsLoading(true)
    initEvaporate(getEvaporateConfig());
  }
  const handleCloseModal = () => {
    setShowModal(false);
    setWarningPopup("")
    apiCall()
  };
  function getEvaporateConfig() {
    return {
      aws_key: papiAwsCredJsonObj?.awsKey,
      bucket: papiAwsCredJsonObj?.bucket,
      awsRegion: papiAwsCredJsonObj?.region,
      cloudfront: true,
      xhrWithCredentials: true,
      signerUrl: UPLOAD_SINGER_URL,
      awsSignatureVersion: "4",
      computeContentMd5: true,
      signHeaders: {
        Authorization: "oauth " + getCheckToken(),
        "Content-Type": "application/vnd.api+json",
        Origin: "localhost"
      },
      aws_url: papiAwsCredJsonObj?.awsUrl,
      cryptoMd5Method: function (data) {
        return AWS?.util?.crypto?.md5(data, "base64");
      },
      cryptoHexEncodedHash256: function (data) {
        return AWS?.util?.crypto?.sha256(data, "hex");
      }
    };
  }

  function initEvaporate(config) {
    Evaporate.create(config).then(
      function success(_e_) {
        // let fileInput = document.getElementById("files"),
        let fileInput = document.getElementsByClassName("filesubmission");
        for (let i = 0; i < fileInput.length; i++) {
          let filePromises = [];
          fileInput[i].onchange = function (evt) {
            handleLoader(true)
            sessionStorage.setItem("fileSubmissionLoResId", this.id);
            // setIsLoading(true); // Set loading state to true
            let file_name;
            let files = evt.target.files;
            for (let i = 0; i < files.length; i++) {
              // const timestamp = new Date().getTime();
              const file = files[i];
              const filename = file.name;
              const validFilenamePattern = /^[a-zA-Z0-9._\-' ()[\]]+$/;
              if (!validFilenamePattern.test(filename)) {
                handleLoader(false);
                setMessageForFailure("");
                setMessageforPopup("");
                setWarningPopup("File name contains special character. Please ensure that the file name only consists of letters, numbers, and standard punctuation marks. Avoid using symbols such as *, %, $, @, &, # etc.")
                setShowModal(true);
                return;
              }
              let promise = _e_
                .add({
                  name: filename,
                  file,
                  progress: function (progress) { }
                })
                .then(function (awsKey) {
                  file_name = awsKey;
                });
              filePromises.push(promise);
            }
            Promise.all(filePromises).then(
              async function () {
                const imgurl = papiAwsCredJsonObj?.awsUrl + "/" + file_name;

                if (imgurl !== null) await uploadFile(imgurl);
              },
              function (reason) { }
            );
            evt.target.value = "";
          };
        }
      },
      function failure(reason) { }
    );
  }

  const uploadFile = async (imageUrl) => {
    handleLoader(true)
    try {
      let loResourceId = sessionStorage.getItem("fileSubmissionLoResId");
      let loId = loResourceId.split("_")[0];
      sessionStorage.removeItem("fileSubmissionLoResId");
      const payload = {
        data: {
          id: loResourceId,
          type: "learningObjectResource",
          attributes: {
            externalReporting: true,
            loResourceType: "Content",
            multipleAttemptEnabled: false,
            previewEnabled: false,
            resourceSubType: "SUBMISSION",
            resourceType: "ACTIVITY",
            submissionEnabled: true,
            submissionState: "PENDING_SUBMISSION",
            submissionUrl: imageUrl
          }
        }
      };

      const response = await apis.uploadFile(payload, loId, loResourceId);
      handleLoader(false, true, response)
    } catch (e) {
      console.log(e);
      handleLoader(false); // Set loading state to true
    }
  }

  const handleDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "downloaded_file.txt";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const extractFileName = (submissionUrl) => {
    if (!submissionUrl) {
      return "";
    }

    try {
      const url = submissionUrl;
      const urlObject = new URL(url);
      const pathSegments = urlObject.pathname.split("/");
      const lastSegment = pathSegments[pathSegments.length - 1];
      const stringWithoutPercent20 = lastSegment.replace(/%20/gi, " ");
      return stringWithoutPercent20;
    } catch (error) {
      console.error("Error parsing URL:", error.message);
      return "";
    }
  };


  useEffect(() => {
    getUploadInfo();
  }, []);




  // -----------------------------------------------
  const playCourse = (cid, mid) => {
    navigate(`/course-player/${cid}`, {
      state: {
        cid,
        mid,
        goBackUrl: location?.pathname,
        crumbs
      }
    });
  };
  const { t } = useTranslation();
  useEffect(() => {
    if (coreContentItem?.loType === "learningProgram" && coreContentItem?.id) {
      callRecursiveFunctionIfLOtypeIsLP(coreContentItem?.id);
    }
  }, []);

  async function callRecursiveFunctionIfLOtypeIsLP(id) {
    const result = await getLerningObjectCourses(id);
    setNestedItem(result);

    result[0]?.instance?.map((cx, i) => {
      return (
        <CertificateInstanceDetails
          handleLoader={handleLoader}
          apiCall={apiCall}
          key={i}
          coreContentItem={cx}
          courseDetails={courseDetails}
          startUi={startUi}
        />
      );
    });
  }

  const { instance_id } = useParams();
  function getClassRoomData(arr, coreContentItem,item) {
    let instructorName = "";
    let tempLoResourceObject = {};

    arr?.instructorNames?.forEach((instructorNameVal) => {
      if (arr?.instructorNames.length > 1) {
        instructorName = instructorNameVal + ",";
      } else {
        instructorName = instructorNameVal;
      }
    });
    tempLoResourceObject.contentType = arr?.contentType;
    tempLoResourceObject.completionDeadline = arr?.completionDeadline || item?.classroomData?.completionDeadline;
    tempLoResourceObject.dateStart = arr?.dateStart || item?.classroomData?.dateStart;
    tempLoResourceObject.instructorName = instructorName;
    tempLoResourceObject.location = arr?.location 
      ? arr?.location
      : arr?.roomLocation
        ? arr?.roomLocation
        : item?.classroomData?.location;
    tempLoResourceObject.seatLimit = arr?.seatLimit || item?.classroomData?.seatLimit;
    tempLoResourceObject.duration = arr?.desiredDuration || item?.classroomData?.duration;
    tempLoResourceObject.roomLocation = arr?.roomLocation ? arr?.roomLocation :item?.classroomData?.roomLocation;
    tempLoResourceObject.virtualrecording = coreContentItem?.sessionRecording;
    return tempLoResourceObject;
  }

  const [activeKey, setActiveKey] = useState("1");
  const toggleAccordion = () => {
    setActiveKey(activeKey === "0" ? null : "0");
  };

  return (
  <>
      <div className="list-view">
        <div className="row mb-4 list-view-block cd justify-content-center justify-content-md-start mx-0">
          <div className="col-auto py-4 px-4">
            {coreContentItem?.loType === "learningProgram" ?
              <img
                src={coreContentItem?.imageUrl || lp_card_img}
                alt={t("loading")}
                className="img-fluid d-block list-view-block-img"
              />
              :
              coreContentItem?.loType === "certification" ?
                <img
                  src={coreContentItem?.imageUrl || certi_card_img}
                  alt={t("loading")}
                  className="img-fluid d-block list-view-block-img"
                />
                :
                coreContentItem?.loType === "jobAid" ?
                  <img
                    src={coreContentItem?.imageUrl || jobaid_card_img}
                    alt={t("loading")}
                    className="img-fluid d-block list-view-block-img"
                  />
                  :
                  <img
                    src={coreContentItem?.imageUrl || card_img}
                    alt={t("loading")}
                    className="img-fluid d-block list-view-block-img"
                  />
            }
          </div>
          <div className="col pe-4 text-padding">
            <p className="head-text">
              <button
                className="head-text btn-as-p"
                onClick={toggleAccordion}
              >{coreContentItem?.name}</button>
              {
                courseDetails?.relationships?.enrollment &&
                ((coreContentItem?.stateOfCourseOrLP === "COMPLETED") ? <span className="completed-btn"> Completed</span> : (coreContentItem?.stateOfCourseOrLP === "STARTED") ? <span className="inprogress-btn"> In Progress </span> : <span className="notStarted-btn"> Not Started</span>)
              }
            </p>
            <p className="sub-text">{coreContentItem?.description}</p>
          </div>
          <div className="list-footer align-items-center">
            <div className="py-2">
              <img src={bookmark} className="px-2" alt=""></img>
              <span className="module"> {coreContentItem?.course?.length} Module(s)</span>
              <span className="seprator px-2"> | </span>
              <span className="my-2 inner-span">
                <span className="capsWord">{coreContentItem?.loType}</span>
                <span className="seprator px-2"> | </span>
                {Array.isArray(coreContentItem?.authorNames) &&
                  coreContentItem?.authorNames?.map((author, index) => (
                    <span key={index} className="capsWord me-2">{author}</span>
                  ))}

                <span className="seprator px-2"> | </span>
                <span>
                  <img src={time} className="px-2" alt="" title="Duration" style={{ "marginBottom": "-1px" }}></img>
                  {secondsToHms(coreContentItem?.duration) || "No Duration"}
                </span>
              </span>
            </div>
            {/* <div className="">{startUi(coreContentItem?.id)}</div> */}
          </div>
          <Accordion defaultActiveKey="1" activeKey={activeKey} onSelect={(key) => setActiveKey(key)} className="horizontal-tab-block accord accord-cls learning-accordion">
            <CustomToggle eventKey="0"> </CustomToggle>
            <Accordion.Collapse eventKey="0">
              <>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3">
                  <Tab Tab eventKey="Module" title="Module(s)">
                    <div className="row mb-4 list-view-block cd justify-content-center justify-content-md-start mx-0 border-0">
                      {coreContentItem && coreContentItem?.course?.filter(item => item.loResourceType === "Pre Work")?.map((item, index) => {
                        const previousCourseHasPassed = (courseIndex === 0) ? "true" : (sessionStorage.getItem("certificate-previous-course-status") || "false");
                        const currentCourseHasPassed = coreContentItem.hasPassed ? "true" : "false";
                        if (coreContentItem?.course?.filter(item => item.loResourceType === "Pre Work")?.length - 1 === index) {
                          sessionStorage.setItem("certificate-previous-course-status", currentCourseHasPassed);
                        }
                        let isRevisit = coreContentItem?.course?.filter(item => item.loResourceType === "Pre Work")[index]?.progressPercent
                        let hasPassed = coreContentItem?.course?.filter(item => item.loResourceType === "Pre Work")[index]?.hasPassed
                        let isOrdered = coreContentItem?.isSubLoOrderEnforced
                        //for pervious module disable
                        // let isPreviousCourseCompleted = courseDetails?.attributes?.isSubLoOrderEnforced ===true && isPreviousInstanceCompleted?
                        //                     ((index === 0 || 
                        //                       coreContentItem?.course?.filter(item => item.loResourceType !== "Test Out")[index - 1]?.progressPercent === 100 || 
                        //                       coreContentItem?.course?.filter(item => item.loResourceType !== "Test Out")[index - 1]?.hasPassed === true)):true
                        //console.log("isOrdered: ", isOrdered, " | courseIndex: ", courseIndex, " | index: ", index, " | previousModuleHasPassed: ", previousModuleHasPassed);
                        const { attributes, id, loResourceType, Mid, resourceSubType, checkList, submissionEnabled, submissionState, submissionUrl, mandatory, learnerAttemptInfo } = { ...item };
                        const cid = Mid.split("_")[0]
                        const { name, duration, contentType } = { ...attributes };
                        if (loResourceType === "Pre Work")
                          return (
                            <>{index === 0 && <h3 className="my-4 fw-bold">Pre Work</h3>}
                              <div key={index} className="accordion-content px-0">
                                <div className="inner-content d-flex flex-wrap flex-row">
                                  <div key={index} className="py-3 align-items-center d-flex px-3">
                                    {moduleImage(item)}
                                  </div>
                                  <div className="module-details-content p-3 flex-fill flex-wrap align-self-center">
                                    <p className="head-text-1">
                                      {
                                        (courseDetails?.relationships?.enrollment && resourceSubType === "SUBMISSION" &&
                                        submissionEnabled) ? (
                                          (submissionState === "APPROVED" || (courseDetails?.relationships?.enrollment ? (isOrdered ? !(previousCourseHasPassed === "true") : false) : true)) 
                                          ? (<span style={{cursor: "pointer"}}>{name}</span>)
                                          : (<label style={{cursor: "pointer"}} htmlFor={Mid}>{name}</label>)
                                        ) : (
                                          <button
                                            className="head-text-1 btn-as-p"
                                            onClick={() => {
                                              playCourse(cid, Mid);
                                            }}
                                            disabled={courseDetails?.relationships?.enrollment ? (isOrdered ? !(previousCourseHasPassed === "true") : false) : true}
                                          >{name}</button>
                                        )
                                      }
                                      {mandatory && ((coreContentItem.loResourceCompletionCount !== coreContentItem.learningObjectInstanceModuleLength) && (mandatory === true) ? <span className="section-btn">Required</span> : <span className="optional-btn">Optional</span>)}
                                      {mandatory === false && ((coreContentItem.loResourceCompletionCount !== coreContentItem.learningObjectInstanceModuleLength) && (mandatory === false) ? <span className="optional-btn">Optional</span> : <span className="p-1 mx-2 mb-2" style={{ color: "#d72509", fontSize: "1.5rem" }}>Optional</span>)}
                                    </p>
                                    <span>{contentType}</span>
                                    {/* ---------------checkList----------- */}
                                    <span>
                                      {(courseDetails?.relationships?.enrollment) && resourceSubType === "CHECKLIST" && <span className="seprator px-2"> | </span>}
                                    </span>
                                    <span>
                                      {(courseDetails?.relationships?.enrollment) && (resourceSubType === "CHECKLIST" ?
                                        checkList === "PENDING" ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#CB9015" }}>
                                          Reviewer evaluation is pending
                                        </span> :
                                          checkList === "PASSED" ?
                                            <>
                                              <span style={{ fontWeight: "bold", fontSize: "15px", color: "#009C3B" }}>Reviewer evaluation is complete. Status - </span><span style={{ fontWeight: "bold", color: "#009C3B" }}>Passed</span>
                                            </> :
                                            checkList === "FAILED" ?
                                              <>
                                                <span style={{ fontWeight: "bold", fontSize: "15px", color: "#EB1000" }}>Reviewer evaluation is complete. Status - </span><span style={{ fontWeight: "bold", color: "#EB1000" }}>Failed</span>
                                              </> :
                                              "" :
                                        "")}
                                    </span>
                                    {/* ----------------------------------------------- */}
                                    {/* --------------------------fileSubmission------------ */}

                                    <span>
                                      {((courseDetails?.relationships?.enrollment) && resourceSubType === "SUBMISSION") && (
                                        <>
                                          <span className="seprator px-2"> | </span>

                                          <span className="sub-text">
                                            {resourceSubType === "SUBMISSION"
                                              ? submissionState ===
                                                "PENDING_APPROVAL"
                                                ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#CB9015" }}>File submission is awaiting for approval : </span>
                                                : submissionState ===
                                                  "APPROVED"
                                                  ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#009C3B" }}>File submission is approved : </span>
                                                  : submissionState ===
                                                    "PENDING_SUBMISSION"
                                                    ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#CB9015" }}>File submission is pending  </span>
                                                    : submissionState ===
                                                      "REJECTED"
                                                      ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#EB1000" }}>File submission is rejected : </span>
                                                      : ""
                                              : ""}
                                          </span>
                                        </>
                                      )}
                                    </span>

                                    {((courseDetails?.relationships?.enrollment) && submissionEnabled) && (
                                      <>
                                        <span className="sub-text">
                                          <a
                                            href="#!" className="file-link" title={extractFileName(submissionUrl)}
                                            onClick={() => {
                                              handleDownload(submissionUrl);
                                            }}>
                                            {extractFileName(submissionUrl)}
                                          </a>
                                        </span>
                                      </>
                                    )}

                                    {/* ---------------------------------------------------------------- */}
                                  </div>
                                  {((courseDetails?.relationships?.enrollment) && resourceSubType === "SUBMISSION") &&
                                    submissionEnabled ? (
                                    <>
                                      <div >
                                        <p className="me-4">
                                          {(courseDetails?.relationships?.enrollment) && (
                                            (submissionState === "PENDING_SUBMISSION" && submissionUrl == "") ? (
                                              <span className="notStarted-btn mt-4">Not Started</span>
                                            ) : (submissionState === "APPROVED") ? (
                                              <span className="completed-btn mt-4">Completed </span>
                                            ) : (submissionState === "REJECTED" && submissionUrl !== "") ? (
                                              <span className="inprogress-btn mt-4">In Progress </span>
                                            ) : (submissionState === "PENDING_APPROVAL" && submissionUrl !== "") ? (
                                              <span className="inprogress-btn mt-4">In Progress </span>
                                            ) : null
                                          )
                                          }
                                        </p>

                                        {submissionState === "PENDING_APPROVAL" && submissionUrl !== "" &&
                                          <div className={`btn primary-btn m-4 ${(courseDetails?.relationships?.enrollment ? (isOrdered ? !(previousCourseHasPassed === "true") : false) : true) ? "disabled" : ""}`}>
                                            <label htmlFor={Mid}> Change File </label></div>}

                                        {submissionState === "PENDING_SUBMISSION" && submissionUrl == "" &&
                                          <div className={`btn primary-btn m-4 ${(courseDetails?.relationships?.enrollment ? (isOrdered ? !(previousCourseHasPassed === "true") : false) : true) ? "disabled" : ""}`}> <label htmlFor={Mid}> Upload File </label> </div>}

                                        {submissionState === "REJECTED" && submissionUrl !== "" && <div className={`btn primary-btn m-4 ${(courseDetails?.relationships?.enrollment ? (isOrdered ? !(previousCourseHasPassed === "true") : false) : true) ? "disabled" : ""}`}> <label htmlFor={Mid}> Upload File </label> </div>}

                                        <input
                                          type="file"
                                          id={Mid}
                                          className="filesubmission"
                                          style={{ display: "none", border: "none" }} />
                                      </div>
                                    </>
                                  ) : <div className="align-self-center ">
                                    <p className="me-4">
                                      {(courseDetails?.relationships?.enrollment) && (
                                        (resourceSubType === "CHECKLIST") ? (
                                          checkList === "PENDING" ? (
                                            <span className="notStarted-btn mt-4">Not Started </span>
                                          ) : checkList === "PASSED" ? (
                                            <span className="completed-btn mt-4">Completed </span>
                                          ) : checkList === "FAILED" ? (
                                            <span className="completed-btn mt-4">Completed </span>
                                          ) : null
                                        ) : resourceSubType === "NONE" ? (
                                          hasPassed ? (
                                            <span className="completed-btn mt-4">Completed</span>
                                          ) : learnerAttemptInfo === "true" ? (
                                            <span className="inprogress-btn mt-4">In Progress</span>
                                          ) : (
                                            <span className="notStarted-btn mt-4">Not Started</span>
                                          )
                                        ) : (
                                          hasPassed ? (
                                            <span className="completed-btn mt-4">Completed </span>
                                          ) : (isRevisit > 0 && isRevisit <= 100) ? (
                                            <span className="inprogress-btn mt-4">In Progress </span>
                                          ) : (
                                            <span className="notStarted-btn mt-4">Not Started </span>
                                          )
                                        )
                                      )}
                                    </p>
                                    <button
                                      className="btn primary-btn m-4"
                                      onClick={() => {
                                        playCourse(cid, Mid);
                                      }}
                                      disabled={courseDetails?.relationships?.enrollment ? (isOrdered ? !(previousCourseHasPassed === "true") : false) : true}>
                                      {resourceSubType === "CHECKLIST" ? (
                                        (checkList === "PENDING" || checkList === "") ? "Play Course" : "Revisit"
                                      ) : resourceSubType === "NONE" ? (
                                        hasPassed ? "Revisit" : learnerAttemptInfo === "true" ? "Continue" : "Play Course"
                                      ) : hasPassed ? "Revisit" : (isRevisit > 0 && isRevisit < 100) ? "Continue" : "Play Course"
                                      }
                                    </button>
                                  </div>}

                                </div>

                                {/* ------------------------- ClassRoom And Virtural Classroom Card--------------------- */}
                                {contentType == "Classroom" || contentType == "Virtual Classroom" ? (
                                  <div className="row mb-4 list-view-block mx-0">
                                    <ClassRoomVirtualClassroomTypeDetails
                                      key={index}
                                      item={{ id, ...getClassRoomData(attributes ?? [], coreContentItem,item
                                      ),
                                      }} />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div></>
                          );
                      })}



                      {<h3 className="my-4 fw-bold">Core Content {
                        coreContentItem?.loResourceCompletionCount && (<span>{(coreContentItem?.loResourceCompletionCount !== coreContentItem?.learningObjectInstanceModuleLength) ? <span className="section-btn">Required {coreContentItem?.loResourceCompletionCount} out of {coreContentItem?.learningObjectInstanceModuleLength}</span> : null}</span>)
                      }

                        {
                          coreContentItem?.loResourceCompletionCount === undefined && (<span>{<span className="section-btn">Required {coreContentItem?.mandatoryModulesCount ? coreContentItem?.mandatoryModulesCount : coreContentItem?.learningObjectInstanceModuleLength} out of {coreContentItem?.learningObjectInstanceModuleLength}</span>}</span>)
                        }
                        {
                          coreContentItem?.loResourceCompletionCount && (<span>{(coreContentItem?.loResourceCompletionCount === coreContentItem?.learningObjectInstanceModuleLength) ? <span className="section-btn">Required {coreContentItem?.loResourceCompletionCount} out of {coreContentItem?.learningObjectInstanceModuleLength}</span> : null}</span>)
                        }</h3>}
                      {coreContentItem && coreContentItem?.course?.filter(item => item.loResourceType !== "Test Out" && item.loResourceType !== "Pre Work")?.map((item, index) => {
                        const previousModuleHasPassed = (courseIndex === 0 && index === 0) ? "true" : (sessionStorage.getItem("certificate-previous-module-status") || "false");
                        const currentModuleHasPassed = item.hasPassed ? "true" : "false";
                        sessionStorage.setItem("certificate-previous-module-status", currentModuleHasPassed);
                        let isRevisit = coreContentItem?.course?.filter(item => item.loResourceType !== "Test Out" && item.loResourceType !== "Pre Work")[index]?.progressPercent
                        let hasPassed = coreContentItem?.course?.filter(item => item.loResourceType !== "Test Out" && item.loResourceType !== "Pre Work")[index]?.hasPassed
                        let isOrdered = coreContentItem?.isSubLoOrderEnforced
                        //for pervious module disable
                        // let isPreviousCourseCompleted = courseDetails?.attributes?.isSubLoOrderEnforced ===true && isPreviousInstanceCompleted?
                        //                     ((index === 0 || 
                        //                       coreContentItem?.course?.filter(item => item.loResourceType !== "Test Out")[index - 1]?.progressPercent === 100 || 
                        //                       coreContentItem?.course?.filter(item => item.loResourceType !== "Test Out")[index - 1]?.hasPassed === true)):true
                        //console.log("isOrdered: ", isOrdered, " | courseIndex: ", courseIndex, " | index: ", index, " | previousModuleHasPassed: ", previousModuleHasPassed);
                        const { attributes, id, loResourceType, Mid, resourceSubType, checkList, submissionEnabled, submissionState, submissionUrl, mandatory, learnerAttemptInfo } = { ...item };
                        const cid = Mid.split("_")[0]
                        const { name, duration, contentType } = { ...attributes };
                        if (loResourceType !== "Test Out" && item.loResourceType !== "Pre Work")
                          return (
                            <div key={index} className="accordion-content px-0">
                              <div className="inner-content d-flex flex-wrap flex-row">
                                <div key={index} className="py-3 align-items-center d-flex px-3">
                                  {moduleImage(item)}
                                </div>
                                <div className="module-details-content p-3 flex-fill flex-wrap align-self-center">
                                  <p className="head-text-1">
                                    {
                                      (resourceSubType === "SUBMISSION" && submissionEnabled) ? (
                                        (submissionState === "APPROVED" || (courseDetails?.relationships?.enrollment ? (isOrdered ? !(previousModuleHasPassed === "true") : false) : true)) 
                                          ? (<span style={{cursor: "pointer"}}>{name}</span>)
                                          : (<label style={{cursor: "pointer"}} htmlFor={Mid}>{name}</label>)
                                      ) : (
                                        <button
                                          className="head-text-1 btn-as-p"
                                          onClick={() => {
                                            playCourse(cid, Mid);
                                          }}
                                          disabled={courseDetails?.relationships?.enrollment ? (isOrdered ? !(previousModuleHasPassed === "true") : false) : true}
                                        >{name}</button>
                                      )
                                    }
                                    {
                                      mandatory && ((coreContentItem.loResourceCompletionCount !== coreContentItem.learningObjectInstanceModuleLength) && (mandatory === true) ? <span className="section-btn">Required</span> : <span className="optional-btn">Optional</span>)
                                    }
                                    {
                                      mandatory === false && ((coreContentItem.loResourceCompletionCount !== coreContentItem.learningObjectInstanceModuleLength) && (mandatory === false) ? <span className="optional-btn">Optional</span> : <span className="p-1 mx-2 mb-2" style={{ color: "#d72509", fontSize: "1.5rem" }}>Optional</span>)
                                    }
                                  </p>
                                  {/* {mandatory ? <span className="p-1 mx-2 mb-2" style={{color: "#d72509",fontSize: "1.5rem"}}> (Required) </span>: null} */}
                                  <span>{contentType}</span>
                                  {/* ---------------checkList----------- */}
                                  <span>
                                    {(courseDetails?.relationships?.enrollment) && resourceSubType === "CHECKLIST" && <span className="seprator px-2"> | </span>}
                                  </span>
                                  <span>
                                    {(courseDetails?.relationships?.enrollment) && (resourceSubType === "CHECKLIST" ?
                                      checkList === "PENDING" ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#CB9015" }}>
                                        Reviewer evaluation is pending
                                      </span> :
                                        checkList === "PASSED" ?
                                          <>
                                            <span style={{ fontWeight: "bold", fontSize: "15px", color: "#009C3B" }}>Reviewer evaluation is complete. Status - </span><span style={{ fontWeight: "bold", color: "#009C3B" }}>Passed</span>
                                          </> :
                                          checkList === "FAILED" ?
                                            <>
                                              <span style={{ fontWeight: "bold", fontSize: "15px", color: "#EB1000" }}>Reviewer evaluation is complete. Status - </span><span style={{ fontWeight: "bold", color: "#EB1000" }}>Failed</span>
                                            </> :
                                            "" :
                                      "")}
                                  </span>
                                  {/* ----------------------------------------------- */}
                                  {/* --------------------------fileSubmission------------ */}

                                  <span>
                                    {((courseDetails?.relationships?.enrollment) && resourceSubType === "SUBMISSION") && (
                                      <>
                                        <span className="seprator px-2"> | </span>

                                        <span className="sub-text">
                                          {resourceSubType === "SUBMISSION"
                                            ? submissionState ===
                                              "PENDING_APPROVAL"
                                              ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#CB9015" }}>File submission is awaiting for approval : </span>
                                              : submissionState ===
                                                "APPROVED"
                                                ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#009C3B" }}>File submission is approved : </span>
                                                : submissionState ===
                                                  "PENDING_SUBMISSION"
                                                  ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#CB9015" }}>File submission is pending  </span>
                                                  : submissionState ===
                                                    "REJECTED"
                                                    ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#EB1000" }}>File submission is rejected : </span>
                                                    : ""
                                            : ""}
                                        </span>
                                      </>
                                    )}
                                  </span>

                                  {((courseDetails?.relationships?.enrollment) && submissionEnabled) && (
                                    <>
                                      <span className="sub-text">
                                        <a
                                          href="#!" className="file-link" title={extractFileName(submissionUrl)}
                                          onClick={() => {
                                            handleDownload(submissionUrl);
                                          }}>
                                          {extractFileName(submissionUrl)}
                                        </a>
                                      </span>
                                    </>
                                  )}

                                  {/* ---------------------------------------------------------------- */}
                                </div>
                                {
                                  resourceSubType === "SUBMISSION" &&
                                    submissionEnabled ? (
                                    <>
                                      <div>
                                        <p className="me-4">
                                          {(courseDetails?.relationships?.enrollment) && (
                                            (submissionState === "PENDING_SUBMISSION") ? (
                                              <span className="notStarted-btn mt-4">Not Started </span>
                                            ) : (submissionState === "APPROVED") ? (
                                              <span className="completed-btn mt-4">Completed </span>
                                            ) : (submissionState === "REJECTED") ? (
                                              <span className="inprogress-btn mt-4">In Progress </span>
                                            ) : (submissionState === "PENDING_APPROVAL") ? (
                                              <span className="inprogress-btn mt-4">In Progress </span>
                                            ) : null
                                          )
                                          }
                                        </p>
                                        {submissionState === "PENDING_APPROVAL" && submissionUrl !== "" &&
                                          <div className={`btn primary-btn m-4 ${(courseDetails?.relationships?.enrollment ? (isOrdered ? !(previousModuleHasPassed === "true") : false) : true) ? "disabled" : ""}`}>
                                            <label htmlFor={Mid}> Change File </label></div>
                                        }

                                        {submissionState === "PENDING_SUBMISSION" && submissionUrl == "" &&
                                          <div className={`btn primary-btn m-4 ${(courseDetails?.relationships?.enrollment ? (isOrdered ? !(previousModuleHasPassed === "true") : false) : true) ? "disabled" : ""}`}> <label htmlFor={Mid}> Upload File </label> </div>
                                        }

                                        {submissionState === "REJECTED" && submissionUrl !== "" && <div className={`btn primary-btn m-4 ${(courseDetails?.relationships?.enrollment ? (isOrdered ? !(previousModuleHasPassed === "true") : false) : true) ? "disabled" : ""}`}> <label htmlFor={Mid}> Upload File </label> </div>}

                                        <input
                                          type="file"
                                          id={Mid}
                                          className="filesubmission"
                                          style={{ display: "none", border: "none" }}
                                        />
                                      </div>
                                    </>
                                  ) : <div className="align-self-center ">
                                    <p className="me-4">
                                      {(courseDetails?.relationships?.enrollment) && (
                                        (resourceSubType === "CHECKLIST") ? (
                                          (checkList === "PENDING") ? (
                                            <span className="notStarted-btn mt-4">Not Started </span>
                                          ) : (checkList === "PASSED") ? (
                                            <span className="completed-btn mt-4">Completed </span>
                                          ) : (checkList === "FAILED") ? (
                                            <span className="completed-btn mt-4">Completed </span>
                                          ) : null
                                        ) : (resourceSubType === "NONE") ? (
                                          hasPassed ? (
                                            <span className="completed-btn mt-4">Completed</span>
                                          ) : learnerAttemptInfo === "true" ? (
                                            <span className="inprogress-btn mt-4">In Progress</span>
                                          ) : (
                                            <span className="notStarted-btn mt-4">Not Started</span>
                                          )
                                        ) : (
                                          hasPassed ? (
                                            <span className="completed-btn mt-4">Completed </span>
                                          ) : (isRevisit > 0 && isRevisit <= 100) ? (
                                            <span className="inprogress-btn mt-4">In Progress </span>
                                          ) : (
                                            <span className="notStarted-btn mt-4">Not Started </span>
                                          )
                                        )
                                      )}
                                    </p>
                                    <button
                                      className="btn primary-btn m-4"
                                      onClick={() => {
                                        playCourse(cid, Mid);
                                      }}
                                      disabled={courseDetails?.relationships?.enrollment ? (isOrdered ? !(previousModuleHasPassed === "true") : false) : true}>
                                      {(
                                        resourceSubType === "CHECKLIST" ? (
                                          (checkList === "PENDING" || checkList === "") ? "Play Course" : "Revisit"
                                        ) : (resourceSubType === "NONE" ? (
                                          hasPassed ? "Revisit" : learnerAttemptInfo === "true" ? "Continue" : "Play Course"
                                        ) :
                                          (hasPassed) ? "Revisit" : (isRevisit) > 0 && (isRevisit) < 100 ? "Continue" : "Play Course"
                                        )
                                      )
                                      }
                                    </button>
                                  </div>
                                }

                              </div>

                              {/* ------------------------- ClassRoom And Virtural Classroom Card--------------------- */}
                              {contentType == "Classroom" || contentType == "Virtual Classroom" ? (
                                <div className="row mb-4 list-view-block mx-0">
                                  <ClassRoomVirtualClassroomTypeDetails
                                    key={index}
                                    item={{ id, ...getClassRoomData(attributes ?? [], coreContentItem,item) }}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                      })}
                    </div>
                  </Tab>
                  {
                    (coreContentItem?.course?.filter((each) => each?.loResourceType === "Test Out")?.length > 0)
                    &&
                    <Tab eventKey="Testout" title="Testout">
                      <div className="row mb-4 list-view-block cd justify-content-center justify-content-md-start mx-0">
                        {coreContentItem?.course?.map((item, index) => {

                          let isRevisit = coreContentItem?.course[index]?.progressPercent
                          let hasPassed = coreContentItem?.course[index]?.hasPassed
                          const { attributes, id, loResourceType, resourceSubType, submissionState, checkList, submissionEnabled, submissionUrl, learnerAttemptInfo } = { ...item };
                          const { name, desiredDuration, contentType } = { ...attributes };
                          if (loResourceType === "Test Out")
                            return (
                              <div key={index} className="accordion-content px-0">

                                <div className="inner-content d-flex flex-wrap flex-row">
                                  <div key={index} className="py-3 align-items-center d-flex px-3">
                                    {moduleImage(item)}
                                  </div>
                                  <div className="module-details-content p-3 flex-fill flex-wrap align-self-center">
                                    <p className="head-text-1">
                                      <button
                                        className="head-text-1 btn-as-p"
                                        onClick={() => {
                                          playCourse(ciid, item?.Mid);
                                        }}
                                        disabled={!(courseDetails?.relationships?.enrollment)}
                                      >
                                        {name}
                                      </button>
                                      {/* { courseDetails?.relationships?.enrollment &&
                              ( hasPassed ? <span className="completed-btn"> Completed</span> : ((isRevisit) > 0 && (isRevisit) <= 100) ? <span className="inprogress-btn"> In Progress</span> : <span className="notStarted-btn"> Not Started</span> )
                              } */}
                                    </p>
                                    <p>
                                      <span>
                                        {contentType}
                                      </span>

                                      {/* ---------------checkList----------- */}
                                      <span>
                                        {(courseDetails?.relationships?.enrollment) && resourceSubType === "CHECKLIST" && <span className="seprator px-2"> | </span>}
                                      </span>
                                      <span>
                                        {(courseDetails?.relationships?.enrollment) && (resourceSubType === "CHECKLIST" ?
                                          checkList === "PENDING" ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#CB9015" }}>
                                            Reviewer evaluation is pending
                                          </span> :
                                            checkList === "PASSED" ?
                                              <>
                                                <span style={{ fontWeight: "bold", fontSize: "15px", color: "#009C3B" }}>Reviewer evaluation is complete. Status - </span><span style={{ fontWeight: "bold", color: "#009C3B" }}>Passed</span>
                                              </> :
                                              checkList === "FAILED" ?
                                                <>
                                                  <span style={{ fontWeight: "bold", fontSize: "15px", color: "#EB1000" }}>Reviewer evaluation is complete. Status - </span><span style={{ fontWeight: "bold", color: "#EB1000" }}>Failed</span>
                                                </> :
                                                "" :
                                          "")}
                                      </span>
                                      {/* ----------------------------------------------- */}

                                      <span>
                                        {resourceSubType === "SUBMISSION" && (
                                          <>
                                            <span className="seprator px-2"> | </span>

                                            <span className="sub-text">
                                              {resourceSubType === "SUBMISSION"
                                                ? submissionState ===
                                                  "PENDING_APPROVAL"
                                                  ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#CB9015" }}>File submission is awaiting for approval : </span>
                                                  : submissionState ===
                                                    "APPROVED"
                                                    ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#009C3B" }}>File submission is approved : </span>
                                                    : submissionState ===
                                                      "PENDING_SUBMISSION"
                                                      ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#CB9015" }}>File submission is pending  </span>
                                                      : submissionState ===
                                                        "REJECTED"
                                                        ? <span style={{ fontWeight: "bold", fontSize: "15px", color: "#EB1000" }}>File submission is rejected : </span>
                                                        : ""
                                                : ""}
                                            </span>
                                          </>
                                        )}
                                      </span>

                                      {submissionEnabled && (
                                        <>
                                          <span className="sub-text">
                                            <a
                                              href="#!" className="file-link" title={extractFileName(submissionUrl)}
                                              onClick={() => {
                                                handleDownload(submissionUrl);
                                              }}>
                                              {extractFileName(submissionUrl)}
                                            </a>
                                          </span>
                                        </>
                                      )}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="me-4">

                                      {(courseDetails?.relationships?.enrollment) && (
                                        (resourceSubType === "CHECKLIST") ? (
                                          checkList === "PENDING" ? (
                                            <span className="notStarted-btn mt-4">Not Started </span>
                                          ) : checkList === "PASSED" ? (
                                            <span className="completed-btn mt-4">Completed </span>
                                          ) : checkList === "FAILED" ? (
                                            <span className="completed-btn mt-4">Completed </span>
                                          ) : null
                                        ) : (resourceSubType === "SUBMISSION") ? (
                                          (submissionState === "PENDING_SUBMISSION") ? (
                                            <span className="notStarted-btn mt-4">Not Started </span>
                                          ) : (submissionState === "APPROVED") ? (
                                            <span className="completed-btn mt-4">Completed </span>
                                          ) : (submissionState === "REJECTED") ? (
                                            <span className="inprogress-btn mt-4">In Progress </span>
                                          ) : (submissionState === "PENDING_APPROVAL") ? (
                                            <span className="inprogress-btn mt-4">In Progress </span>
                                          ) : null
                                        ) : (resourceSubType === "NONE") ? (
                                          hasPassed ? (
                                            <span className="completed-btn mt-4">Completed</span>
                                          ) : learnerAttemptInfo === "true" ? (
                                            <span className="inprogress-btn mt-4">In Progress</span>
                                          ) : (
                                            <span className="notStarted-btn mt-4">Not Started</span>
                                          )
                                        ) : (
                                          hasPassed ? (
                                            <span className="completed-btn mt-4">Completed </span>
                                          ) : (isRevisit > 0 && isRevisit <= 100) ? (
                                            <span className="inprogress-btn mt-4">In Progress </span>
                                          ) : (
                                            <span className="notStarted-btn mt-4">Not Started </span>
                                          )
                                        )
                                      )}

                                    </p>
                                    <button
                                      className="btn primary-btn m-4"
                                      onClick={() => {
                                        playCourse(ciid, item?.Mid);
                                      }}
                                      disabled={!(courseDetails?.relationships?.enrollment)}
                                    >
                                      {
                                        resourceSubType === "CHECKLIST" ? (
                                          (checkList === "PENDING" || checkList === "") ? "Play Course" : "Revisit"
                                        ) : (resourceSubType === "NONE" ? (
                                          hasPassed ? "Revisit" : learnerAttemptInfo === "true" ? "Continue" : "Play Course"
                                        ) :
                                          (hasPassed) ? "Revisit" : (isRevisit) > 0 && (isRevisit) <= 100 ? "Continue" : "Play Course"
                                        )}
                                    </button>
                                  </div>
                                </div>


                                {/* ------------------------- ClassRoom And Virtural Classroom Card--------------------- */}
                                {contentType == "Classroom" || contentType == "Virtual Classroom" ? (
                                  <div className="row mb-4 list-view-block mx-0">
                                    <ClassRoomVirtualClassroomTypeDetails
                                      key={index}
                                      item={{ id, ...getClassRoomData(attributes ?? [], coreContentItem,item) }}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            );
                        })}
                      </div>
                    </Tab>
                  }
                </Tabs>
                {nestedItem && nestedItem[0]?.instance?.map((cx, i) => {
                  return (
                    <CertificateInstanceDetails
                      handleLoader={handleLoader}
                      apiCall={apiCall}
                      key={i}
                      coreContentItem={cx}
                      courseDetails={courseDetails}
                      startUi={startUi}
                    />
                  );
                })}
              </>
            </Accordion.Collapse>
          </Accordion>
          <ModalforSuccessAndFailure messageforPopup={messageforPopup} warningPopup={warningPopup} messageForFailure={messageForFailure} show={showModal} onClose={handleCloseModal} />
        </div>
      </div>
    </>
  );
}
